import { useState, useContext } from 'react'
import { useHistory, Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { handleUserLogin } from 'services/api/users'

import { Context } from 'contexts/context'

import Input from 'components/Form/LabelInput'
import BtnOrange from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'

import { 
  Login, 
  SubTitle, 
  InputBox, 
  BtnBox, 
  ForgotPassword, 
  Form, 
  SSOButton,
  SSOButtonContainer
} from './styled'

import Validation from './validation'

const Page = ({ data }) => {
    const { signIn } = useContext(Context)

    const [signIng, setSignIng] = useState(false)

    const [messages, setMessages] = useState({ 
        messages: [], 
        alert: '' 
    })

    console.log(data)

    const history = useHistory()
    const params = useParams()

    const formik = useFormik({
        initialValues: { 
            email: '', 
            password: '' 
        },
        validationSchema: Yup.object(Validation(Yup)),
        onSubmit: () => handleUserLogin(history, {...formik.values, company: data?.id}, setMessages)
    })

    const handleSSOLogin = async () => {
        setSignIng(true)

        try {
            const aadToken = await signIn()

            handleUserLogin(history, { 
                aadToken,
                appName: "exph", 
                appUrl: process.env.REACT_APP_URL_APP
            }, setMessages)
        } catch(error) {
            console.log(error)
        } finally {
            setSignIng(false)
        }
    }
  
    return (
      <Login>
        <h1>Login</h1>
  
        <SubTitle>Entre com o seu email e senha.</SubTitle>
  
        <Form onSubmit={formik.handleSubmit}>
          <Messages formMessages={messages.messages} alert={messages.alert} />
  
          <InputBox>
            <Input
              labelColor="var(--color-green)"
              type="email"
              name="email"
              label="Email"
              formik={formik}
            />
          </InputBox>
  
          <InputBox>
            <Input
              labelColor="var(--color-green)"
              type="password"
              name="password"
              label="Senha"
              formik={formik}
            />
          </InputBox>
  
          <ForgotPassword>
          <Link to={data ? `${params.slug}/esqueci-senha` : "/esqueci-senha"}>Esqueceu a senha?</Link>
        </ForgotPassword>
  
          <BtnBox>
          <BtnOrange style={{ backgroundColor: data ? data.secondary_color : "var(--color-yellow)" }} type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <Spinner /> : "Entrar"}
          </BtnOrange>
          </BtnBox>
  
          {/* <SSOButtonContainer>
            <SSOButton type='button' onClick={handleSSOLogin}>{signIng ? <Spinner /> : "Entrar com SSO"}</SSOButton>
          </SSOButtonContainer> */}
        </Form>
      </Login>
    )
}

export default Page