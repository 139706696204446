import styled from 'styled-components'

export const Login = styled.section`
  width: 100vw;
  height: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  background-image: url('edificio.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`


export const Form = styled.div`
  width: 80%;
  margin: auto;
`

export const LoginBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  border-radius: 30px;
  background-color: var(--color-white);

  @media (max-width: 1200px) {
    margin: auto;
  }
`
export const Logo = styled.div`
  padding-top: 1rem;

  & img {
    max-width: 400px;
    max-height: 100px;
  }
`